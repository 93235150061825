import { createTheme } from "@mantine/core";

export const theme = createTheme({
  fontFamily: "'Inter Variable', sans-serif",
  components: {
    Title: {
      styles: {
        root: {
          letterSpacing: "-1px",
        },
      },
    },
  },
  headings: {
    sizes: {
      h1: {
        fontSize: "2.5rem",
        fontWeight: "800",
        lineHeight: "3rem",
      },
      h2: {
        fontSize: "1.75rem",
        fontWeight: "800",
        lineHeight: "2.25rem",
      },
    },
  },
});
