import "@fontsource-variable/inter";
import "@mantine/core/styles.css";
import '@mantine/notifications/styles.css';
import React from "react";
import {MantineProvider} from "@mantine/core";
import {theme} from "./src/theme";
import './src/index.css';
import {Notifications} from "@mantine/notifications";

export const wrapPageElement = ({element}) => {
    return (
        <MantineProvider defaultColorScheme="auto" theme={theme}>
            <Notifications/>
            {element}
        </MantineProvider>
    );
};
